<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height ma-0 pa-0" fluid>
        <div id="login-bg" class="fill-height"></div>
        <div id="login-bg2" class="fill-height"></div>
        <div id="login-overlay" class="fill-height">
          <v-row align="center" justify="center">
            <v-col class="px-0" cols="10" sm="6" md="4" lg="3">
              <v-card class="elevation-12 px-4 pt-10 pb-5">
                <v-card-text v-show="!forgot_password">
                  <v-row align="center" justify="center">
                    <v-flex
                      align-center
                      justify-center
                      layout
                      text-xs-center
                      class="mb-2"
                    >
                      <v-img
                        contain
                        :height="img_height"
                        max-width="350"
                        :src="login_img"
                      />
                    </v-flex>
                  </v-row>
                  <!-- <v-row align="center" justify="center">
                    <v-card-title
                      class="text-uppercase font-weight-black  black--text display-1"
                    >
                      {{ $t("welcome") }}
                    </v-card-title>
                  </v-row> -->
                  <v-row class="mb-6 mt-2">
                    <v-alert
                      text
                      type="error"
                      dismissible
                      width="100%"
                      v-if="error_login_txt"
                    >
                      {{ error_login_txt }}
                    </v-alert>
                    <v-alert
                      text
                      type="success"
                      dismissible
                      width="100%"
                      v-if="new_password_sent"
                    >
                      {{ $t("new_password_sent") }}
                    </v-alert>
                  </v-row>
                  <v-form v-model="valid_login" ref="form_login">
                    <v-text-field
                      :label="$t('user') + ' (email)'"
                      name="login"
                      append-icon="mdi-account-outline"
                      type="text"
                      v-model="username"
                      :rules="email_rules"
                    />

                    <v-text-field
                      id="password"
                      :label="$t('password')"
                      name="password"
                      append-icon="mdi-lock-outline"
                      type="password"
                      v-model="password"
                      :rules="password_rules"
                    />
                  </v-form>
                  <v-row class="mt-5" align="center" justify="center">
                    <v-btn
                      @click="login"
                      rounded
                      outlined
                      block
                      large
                      class="px-4"
                      color="primary"
                      :disabled="!valid_login"
                      :loading="loading_login"
                      >{{ $t("enter") }}</v-btn
                    >
                  </v-row>
                  <v-row class="mt-6" align="center" justify="center">
                    <v-btn
                      @click="
                        forgot_password = true;
                        new_password_sent = false;
                      "
                      text
                      rounded
                      block
                      small
                      class="px-4 mt-12"
                      color="grey"
                      >{{ $t("forgot_password") }}</v-btn
                    >
                  </v-row>
                </v-card-text>
                <v-card-text v-show="forgot_password">
                  <v-row align="center" justify="center" class="">
                    <v-card-title
                      class="text-uppercase font-weight-black  black--text headline"
                    >
                      {{ $t("whats_your_email") }}
                    </v-card-title>
                  </v-row>
                  <v-row
                    align="center"
                    justify="center"
                    class="mb-8 text-center"
                  >
                    {{ $t("receive_recover_email") }}
                  </v-row>
                  <v-form v-model="remember_password" ref="form_remember">
                    <v-text-field
                      :label="$t('user') + ' (email)'"
                      name="login"
                      append-icon="mdi-account-outline"
                      type="text"
                      v-model="username_to_remeber"
                      :rules="email_rules"
                    />
                  </v-form>
                  <v-row class="mt-5" align="center" justify="center">
                    <v-btn
                      @click="requestPasswordRenew"
                      rounded
                      outlined
                      block
                      large
                      class="px-4"
                      color="primary"
                      :disabled="!remember_password"
                      :loading="loading_remember"
                      >{{ $t("remember_password") }}</v-btn
                    >
                    <v-btn
                      @click="forgot_password = false"
                      text
                      rounded
                      block
                      large
                      class="px-4 mt-5 mb-2"
                      color="grey"
                      ><v-icon left>mdi-arrow-left</v-icon>
                      {{ $t("cancel") }}</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
              <div v-if="is_distributor" class="text-center white--text mt-4">
                <v-img
                  contain
                  height="40"
                  src="/img/logo/veetal-claim-horitontal-byn-sma.png"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>

    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :multi-line="false"
    >
      <div>
        <h4 class="uppercase">{{ snackbar.title }}</h4>
        <span>{{ snackbar.text }}</span>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          dark
          icon
          text
          x-small
          @click="snackbar.snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    forgot_password: false,
    first_time: true,
    username: "",
    password: "",
    username_to_remeber: "",
    valid_login: true,
    loading_login: false,
    loading_remember: false,
    remember_password: false,
    new_password_sent: false
  }),
  methods: {
    login() {
      this.loading_login = true;
      this.$store
        .dispatch("setAuthenticated", this.credentials)
        .then(() => {
          if (this.error_login_txt == null) {
            // Change Layout
            this.$store.dispatch("setAppLayout");

            // Set Tawk.to user data
            // this.$Tawk.$updateChatUser({
            //   //name: this.current_user.name,
            //   email: this.current_user.username
            // });
            /* window.Tawk_API.showWidget();
            window.Tawk_API.setAttributes({
              name: this.current_user.name,
              email: this.current_user.username
            }); */

            // Go to dashboard
            this.$router.push({
              name: "dashboard",
              params: {
                hotel_slug: this.current_user.default_hotel_slug
              }
            });
          }
          this.loading_login = false;
        })
        .catch(() => {
          this.loading_login = false;
          this.$store.dispatch("setSnackbar", {
            title: "Uuups! Sembla que no tens conexió",
            text: "Algun problema ha succeit al intentar entrar",
            color: "error"
          });
        });
    },
    requestPasswordRenew() {
      this.loading_remember = true;
      this.$store
        .dispatch("rememberPassword", this.username_to_remeber)
        .then(() => {
          this.forgot_password = false;
          this.loading_remember = false;
          this.new_password_sent = true;
          this.$refs.form_login.reset();
          this.$refs.form_login.resetValidation();
          this.$refs.form_remember.reset();
          this.$refs.form_remember.resetValidation();
        })
        .catch(() => {
          this.loading_remember = false;
          this.$store.dispatch("setSnackbar", {
            title: "Uuups! Sembla que no tens conexió",
            text:
              "Algun problema ha succeit al intentar recordar la contrassenya",
            color: "error"
          });
        });
    }
  },
  computed: {
    customer() {
      return window.location.hostname.split(".")[0];
    },
    is_distributor() {
      let distributors = ["bls", "roomtability", "hotelcare", "rcd"];
      return distributors.includes(this.customer);
    },
    img_height() {
      return this.is_distributor ? 220 : 220;
    },
    login_img() {
      if (!this.is_distributor) {
        return "/img/logo/veetal-claim-vertical-color-med.png";
      }
      switch (this.customer) {
        case "rcd":
          return (
            "https://" +
            this.customer +
            "-api.veetal.app/img/customers/rcd/Logo_HotelsDot_Blau.png"
          );
        case "bls":
          return (
            "https://" +
            this.customer +
            // "bls" +
            "-api.veetal.app/img/customers/bls/roomtability_logo_wide.png"
          );
        default:
          return "/img/logo/veetal-claim-vertical-color-med.png";
      }
    },
    credentials() {
      return {
        username: this.username,
        password: this.password
      };
    },
    ...mapState({
      snackbar: state => state.snackbar
    }),
    ...mapGetters({
      authenticated: "authenticated",
      error_login_txt: "error_login_txt",
      name_rules: "name_rules",
      email_rules: "email_rules",
      date_rules: "date_rules",
      password_rules: "password_rules",
      current_user: "current_user"
    })
  },
  created() {
    // Que miri si estic logat
    if (this.authenticated) {
      this.$store.dispatch("setAppLayout");

      // Si estoy en la página de login, redirijo a la HOME
      if (
        this.$route.name == "login" ||
        this.$route.name == "home" ||
        this.$route.name === null
      ) {
        this.$router.push({
          name: "dashboard",
          params: {
            hotel_slug: this.current_user.default_hotel_slug
          }
        });
      }
    } else {
      window.Tawk_API.hideWidget();
    }
  }
};
</script>

<style>
#login-bg {
  width: 100%;
  background: url("/img/bg/bg2.jpg") no-repeat bottom !important;
  background-size: cover !important;
  -webkit-filter: blur(2px), grayscale(50%);
  -moz-filter: blur(2px), grayscale(100%);
  -o-filter: blur(2px) grayscale(100%);
  -ms-filter: blur(2px) grayscale(100%);
  filter: blur(2px) grayscale(100%);
}
#login-bg2 {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(31, 100, 153, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(31, 100, 153, 1) 0%,
    rgba(31, 59, 143, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(31, 100, 153, 1)),
    color-stop(100%, rgba(31, 59, 143, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(31, 100, 153, 1) 0%,
    rgba(31, 59, 143, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(31, 100, 153, 1) 0%,
    rgba(31, 59, 143, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(31, 100, 153, 1) 0%,
    rgba(31, 59, 143, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(31, 100, 153, 1) 0%,
    rgba(31, 59, 143, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f6499', endColorstr='#1f3b8f', GradientType=1 );
  /*
  background: rgba(72,107,133,1);
background: -moz-linear-gradient(-45deg, rgba(72,107,133,1) 0%, rgba(23,22,102,1) 100%);
background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(72,107,133,1)), color-stop(100%, rgba(23,22,102,1)));
background: -webkit-linear-gradient(-45deg, rgba(72,107,133,1) 0%, rgba(23,22,102,1) 100%);
background: -o-linear-gradient(-45deg, rgba(72,107,133,1) 0%, rgba(23,22,102,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(72,107,133,1) 0%, rgba(23,22,102,1) 100%);
background: linear-gradient(135deg, rgba(72,107,133,1) 0%, rgba(23,22,102,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#486b85', endColorstr='#171666', GradientType=1 );
  
  background: rgba(86,154,209,1);
background: -moz-linear-gradient(-45deg, rgba(86,154,209,1) 0%, rgba(49,46,194,1) 100%);
background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(86,154,209,1)), color-stop(100%, rgba(49,46,194,1)));
background: -webkit-linear-gradient(-45deg, rgba(86,154,209,1) 0%, rgba(49,46,194,1) 100%);
background: -o-linear-gradient(-45deg, rgba(86,154,209,1) 0%, rgba(49,46,194,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(86,154,209,1) 0%, rgba(49,46,194,1) 100%);
background: linear-gradient(135deg, rgba(86,154,209,1) 0%, rgba(49,46,194,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#569ad1', endColorstr='#312ec2', GradientType=1 );*/
  opacity: 0.85;
}
#login-overlay {
  position: absolute;
  width: 100%;
  display: flex;
}
</style>
