<template>
  <a-page
    id="user-management-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-tooltip bottom dark color="#333333">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-1"
            v-show="!show_editing && !show_new_member"
            @click="showAddMember"
            rounded
            color="secondary"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            {{ $t("add_member") }}
          </v-btn>
        </template>
        <span>
          {{ $t("new_member") }}
        </span>
      </v-tooltip>
    </template>

    <!-- PAGE HEADER -->
    <!-- NEW MEMBER MESSAGE -->
    <div
      v-if="show_new_member"
      class="d-flex justify-center align-center text-center white--text mb-6"
      style="position:relative"
    >
      <div class="px-10 py-12">
        <span class="text-h5 d-block">{{ $t("creating_new_member") }}</span>
      </div>
    </div>
    <!-- EDIT MEMBER MESSAGE -->
    <div
      v-if="show_editing"
      class="d-flex justify-center align-center text-center white--text mb-6"
      style="position:relative"
    >
      <div class="px-10 py-12">
        <span class="text-h5 d-block">{{ $t("modifying_member") }}</span>
      </div>
    </div>
    <!-- KPIS -->
    <div
      v-if="!show_editing && !show_new_member"
      class="d-flex justify-center align-center text-center white--text mb-6"
      style="position:relative"
    >
      <div class="px-10">
        <span class="text-h1 d-block">{{ user_count }}</span>
        <span class="text-h5 d-block">{{ $t("members") }}</span>
      </div>
      <div class="px-10">
        <span class="text-h1 d-block">{{ active_member_count }}</span>
        <span class="text-h5 d-block">{{ $t("actives") }}</span>
      </div>
    </div>
    <!-- end PAGE HEADER -->

    <!-- LIST -->
    <v-card v-if="!show_editing && !show_new_member">
      <v-app-bar flat color="white">
        <v-toolbar-title class="title font-weight-bold d-flex align-center">
          {{ $t("team_members") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          class=""
          block
          v-model="search"
          rounded
          outlined
          dense
          clearable
          hide-details
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('search_user')"
          style="max-width:300px"
        ></v-text-field>
      </v-app-bar>
      <v-card-text>
        <div class="table-header d-flex"></div>
        <v-data-table
          :headers="headers"
          :items="filteredUsers"
          hide-default-footer
          disable-pagination
          class="elevation-0"
          :item-class="getDatatableRowClass"
          @click:row="showModifyMember"
        >
          <template v-slot:[`item.username`]="{ item }">
            {{ item.username }}
            <v-avatar v-if="item.profile_id === 99" class="ml-2" size="16"
              ><img src="/img/logo/veetal-imagotipo-sma.png"
            /></v-avatar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }} {{ item.surnames }}
          </template>
          <template v-slot:[`item.access_to`]="{ item }">
            <v-chip small color="secondary" v-if="getHotelNumber(item) > 0"
              >{{ getHotelNumber(item) }} {{ $t("hotels") }}
            </v-chip>
            <v-chip
              small
              color="secondary"
              v-else-if="getRestaurantNumber(item) > 0"
              >{{ getRestaurantNumber(item) }} {{ $t("restaurants") }}
            </v-chip>
            <v-chip small v-else>{{ $t("no_establishment") }} </v-chip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <template v-if="item.active">
              <v-chip small color="secondary">{{ $t("active") }}</v-chip>
            </template>
            <template v-else>
              <v-chip small>{{ $t("unactive") }}</v-chip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click.stop="showChangePassword(item)" icon color="secondary"
              ><v-icon small>mdi-key</v-icon></v-btn
            >
            <v-btn
              @click.stop="showDeleteMember(item)"
              icon
              color="secondary"
              v-if="item.id !== current_user.id"
              ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
            >
            <v-btn
              @click.stop="deactivateUser(item.id)"
              v-if="item.active && item.id !== current_user.id"
              icon
              color="secondary"
              ><v-icon small>mdi-eye-off-outline</v-icon></v-btn
            >
            <v-btn
              @click.stop="activateUser(item.id)"
              v-if="!item.active && item.id !== current_user.id"
              icon
              color="secondary"
              ><v-icon small>mdi-eye-outline</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- NEW MEMBER FORM -->
    <v-card v-if="show_new_member">
      <v-app-bar flat color="white">
        <v-toolbar-title class="title font-weight-bold">
          <v-btn icon fab @click="hideAddMember" class="mb-1 mr-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <template v-if="item_to_edit.username"
            >{{ $t("creating_member") }}:
            <b>{{ item_to_edit.username }}</b></template
          >
          <template v-else>{{ $t("new_member") }}</template>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="secondary"
          :disabled="!valid_add"
          @click="setNewAppUser"
          :loading="saving"
          >{{ $t("add") }}</v-btn
        >
      </v-app-bar>
      <v-card-text class=" pl-16">
        <v-form v-model="valid_add" ref="valid_add">
          <v-row>
            <v-col cols="12" md="12">
              <v-card-title class="pl-0">{{ $t("general") }}</v-card-title>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="item_to_edit.username"
                    password
                    maxlength="50"
                    counter
                    :rules="email_rules"
                    :label="$t('username')"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="item_to_edit.password"
                    minlength="8"
                    counter
                    :rules="password_rules"
                    type="password"
                    :label="$t('password')"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="item_to_edit.name"
                    maxlength="50"
                    counter
                    :rules="name_rules"
                    :label="$t('name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="item_to_edit.surnames"
                    maxlength="50"
                    counter
                    :label="$t('surname')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <permissions-editor :item="item_to_edit"></permissions-editor>
              <v-card-title class="pl-0">{{
                $t("which_establishments_can_see")
              }}</v-card-title>
              <v-row>
                <v-list class="pa-4" style="width: 100%;">
                  <v-list-item
                    v-for="(hotel, hotel_code) in item_to_edit.hotel"
                    :key="hotel.id"
                    class="mb-1 rounded "
                    :class="{
                      'grey lighten-4': !hotel.has_access,
                      secondary: hotel.has_access
                    }"
                  >
                    <v-list-item-title
                      class="align-start"
                      style="max-width: 30%;"
                      :class="getLevelClass(hotel_code)"
                    >
                      <v-switch
                        :dark="hotel.has_access"
                        class="ma-3"
                        :class="getLevelClass(hotel_code)"
                        v-model="hotel.has_access"
                        hide-details
                        :label="hotel.name"
                      ></v-switch>
                    </v-list-item-title>
                    <v-list-item-content>
                      <div v-if="hotel.has_access">
                        <v-row
                          v-for="(task, task_code) in hotel.tasks"
                          :key="task_code"
                        >
                          <v-col cols="6" class="py-0 white--text text-right">
                            <v-list-item-title class="subtitle-2 pt-3">
                              {{ $t(`task-${task_code}`) }}
                            </v-list-item-title>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-select
                              dense
                              class="py-0 mt-0 mb-1"
                              rounded
                              :items="getEmailTemplates(hotel)"
                              solo
                              color="secondary"
                              hide-details
                              v-model="hotel.email_template[task_code]"
                            ></v-select>
                          </v-col>
                          <v-divider></v-divider>
                        </v-row>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
            <v-col cols="12" md="6"> </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- EDIT MEMBER FORM -->
    <v-card v-if="show_editing">
      <v-app-bar flat color="white">
        <v-toolbar-title class="title font-weight-bold">
          <v-btn icon fab @click="hideModifyMember" class="mb-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          {{ $t("you_are_editing") }} {{ item_to_edit.username }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="secondary"
          :disabled="!valid_edit"
          @click="setAppUser"
          :loading="saving"
          >{{ $t("save") }}</v-btn
        >
      </v-app-bar>
      <v-card-text class=" pl-16">
        <v-form v-model="valid_edit">
          <v-row>
            <v-col cols="12" md="12">
              <v-card-title class="pl-0">{{ $t("general") }}</v-card-title>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="item_to_edit.name"
                    maxlength="50"
                    counter
                    :rules="name_rules"
                    :label="$t('name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item_to_edit.surnames"
                    maxlength="50"
                    counter
                    :label="$t('surname')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <permissions-editor
                :item="item_to_edit"
                :all_available="isSuperuser"
              ></permissions-editor>
              <v-card-title class="pl-0">{{
                $t("which_establishments_can_see")
              }}</v-card-title>
              <v-row>
                <v-list class="pa-4" style="width: 100%;">
                  <v-list-item
                    v-for="(hotel, hotel_code) in item_to_edit.hotel"
                    :key="hotel.id"
                    class="mb-1 rounded "
                    :class="{
                      'grey lighten-4': !hotel.has_access,
                      secondary: hotel.has_access
                    }"
                  >
                    <v-list-item-title
                      class="align-start"
                      style="max-width: 30%;"
                      :class="getLevelClass(hotel_code)"
                    >
                      <v-switch
                        :dark="hotel.has_access"
                        class="ma-3"
                        :class="getLevelClass(hotel_code)"
                        v-model="hotel.has_access"
                        hide-details
                        :label="hotel.name"
                      ></v-switch>
                    </v-list-item-title>
                    <v-list-item-content>
                      <div v-if="hotel.has_access">
                        <v-row
                          v-for="(task, task_code) in hotel.tasks"
                          :key="task_code"
                        >
                          <v-col cols="6" class="py-0 white--text text-right">
                            <v-list-item-title class="subtitle-2 pt-3">
                              {{ $t(`task-${task_code}`) }}
                            </v-list-item-title>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-select
                              dense
                              class="py-0 mt-0 mb-1"
                              rounded
                              :items="getEmailTemplates(hotel)"
                              solo
                              color="secondary"
                              hide-details
                              v-model="hotel.email_template[task_code]"
                            ></v-select>
                          </v-col>
                          <v-divider></v-divider>
                        </v-row>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- CHANGE PASSWORD DIALOG -->
    <v-dialog
      v-model="show_change_password"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="460"
      scrollable
    >
      <v-card class="">
        <v-card-title class="headline justify-center" v-if="item_to_edit"
          >{{ item_to_edit.username }}
          <v-spacer></v-spacer>
          <v-btn
            style="position:absolute; top:13px; right:10px;"
            icon
            @click="show_change_password = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="white">
          <v-form v-model="password_valid" ref="password_form">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <v-text-field
                  clearable
                  v-model="new_password"
                  :label="$t('new_password')"
                  type="password"
                  :rules="password_rules"
                  @change="repeat_pwd_validate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="new_password2"
                  clearable
                  v-model="new_password2"
                  :label="$t('repeat_password')"
                  type="password"
                  :rules="repeatPasswordRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4 px-6">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            :disabled="!password_valid"
            :loading="saving_password"
            @click="changeUserPassword()"
          >
            {{ $t("change") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE USER -->
    <v-dialog
      v-model="show_delete_member"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          {{ $t("sure_delete_user", { username: item_to_edit.username }) }}
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteUser()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteMember"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import apiClient from "@/services/Environment";
import aPage from "@/components/aPage";
import PermissionsEditor from "@/components/permissions/PermissionsEditor";

export default {
  components: {
    aPage,
    PermissionsEditor
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 280, //Mandatory number
    item_to_edit: {},
    pwd1: "",
    pwd2: "",
    user_to_seehotels: "",
    show_new_member: false,
    show_editing: false,
    show_delete_member: false,
    show_change_password: false,
    // type: {},
    saving: false,
    saving_password: false,
    valid_edit: true,
    valid_add: true,
    password_valid: true,
    new_password: "",
    new_password2: "",
    search: ""
  }),
  methods: {
    getEmailTemplates(hotel) {
      return (hotel.children_number > 0
        ? this.group_email_templates
        : hotel.type === "hotel"
        ? this.hotel_email_templates
        : this.restaurant_email_templates
      ).filter(item =>
        !item.visibility || this.item_to_edit.permissions[item.visibility]
          ? item
          : null
      );
    },
    showChangePassword(member) {
      this.show_change_password = true;
      this.item_to_edit = member;
    },
    repeat_pwd_validate() {
      this.$refs.new_password2.validate();
    },
    changeUserPassword() {
      if (this.password_valid) {
        this.saving_password = true;
        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("modifying_password"),
          color: "secondary"
        });
        const data = {
          id: this.item_to_edit.id,
          new_password: this.new_password
        };
        this.show_change_password = false;

        this.$store
          .dispatch("changeAppUserPassword", data)
          .then(() => {
            this.saving_password = false;
            this.new_password = "";
            this.new_password2 = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("modified_password"),
              text: this.$t("modified_user_ok", {
                username: this.item_to_edit.username
              }),
              color: "secondary"
            });
            this.item_to_edit = {};
          })
          .catch(() => {
            this.saving_password = false;
            this.new_password = "";
            this.new_password2 = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
            this.item_to_edit = {};
          });
      }
    },
    getHotelNumber(user) {
      return Object.values(user.hotel).filter(
        e => e.type == "hotel" && e.has_access
      ).length;
    },
    getRestaurantNumber(user) {
      return Object.values(user.hotel).filter(
        e => e.type == "restaurant" && e.has_access
      ).length;
    },
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    showAddMember() {
      this.show_new_member = true;
      this.item_to_edit = JSON.parse(JSON.stringify(this.new_member_model));
    },
    hideAddMember() {
      this.show_new_member = false;
      this.item_to_edit = {};
    },
    showModifyMember(member) {
      this.show_editing = true;
      this.item_to_edit = JSON.parse(JSON.stringify(member));
    },
    hideModifyMember() {
      this.show_editing = false;
      this.item_to_edit = {};
    },
    showDeleteMember(member) {
      this.show_delete_member = true;
      this.item_to_edit = member;
    },
    hideDeleteMember() {
      this.show_delete_member = false;
      this.item_to_edit = {};
    },
    getHotelLevel: function(hotel_order) {
      // Calculates hotel depth level depending on how many '-' are in the hotel_order string "todos los hoteles-grupo1-hotel1"
      var level = 0;
      if (hotel_order.match(/-/g)) {
        level = hotel_order.match(/-/g).length;
      }
      return level * 3;
    },
    getLevelClass: function(hotel_order) {
      var level = this.getHotelLevel(hotel_order);
      return level > 0 ? "pl-" + level : "";
    },
    getHotelList: function(hotel) {
      var text = [];
      Object.values(hotel).forEach(function(element) {
        text.push(element.name);
      });
      return text.join(",");
    },
    getUserHotels: function(user) {
      var hotels = 0;
      var restaurants = 0;
      this.users.forEach(function(element) {
        hotels =
          hotels + (element.type === "hotel" && element.has_access ? 1 : 0);
        restaurants =
          restaurants +
          (element.type === "restaurant" && element.has_access ? 1 : 0);
      });
      var hotels_text = hotels === 1 ? " hotel" : " hoteles";
      var restaurants_text =
        restaurants === 1 ? " restaurante" : " restaurantes";
      var text =
        (hotels > 0 ? hotels + hotels_text : "") +
        " " +
        (restaurants > 0 ? restaurants + restaurants_text : "");
      return text;
    },
    deactivateUser: function(user_id) {
      if (user_id == this.current_user.id) {
        this.$store.dispatch("setSnackbar", {
          title: vm.$t("cannot_deactivate_member"),
          color: "error"
        });
        return;
      }

      var vm = this;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("deactivating_member"),
        color: "secondary"
      });

      this.$store
        .dispatch("deactivateUser", user_id)
        .then(() => {
          this.show_delete_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("deactivated_member"),
            // text: this.$t("modified_user_ok", { username : this.item_to_edit.username }),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    activateUser: function(user_id) {
      var vm = this;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("activating_member"),
        color: "secondary"
      });

      this.$store
        .dispatch("activateUser", user_id)
        .then(() => {
          this.show_delete_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("activated_member"),
            // text: this.$t("modified_user_ok", { username : this.item_to_edit.username }),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    deleteUser: function() {
      var vm = this;
      var user_to_delete = this.user_to_delete;
      this.user_to_delete = "";
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("deleting_member"),
        color: "secondary"
      });

      this.show_delete_password = false;
      this.$store
        .dispatch("deleteUser", this.item_to_edit.id)
        .then(() => {
          this.show_delete_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("deleted_member"),
            text: this.$t("modified_user_ok", {
              username: this.item_to_edit.username
            }),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    setAppUser: function() {
      var vm = this;
      this.saving = true;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("modifying_member"),
        color: "secondary"
      });
      this.$store
        .dispatch("updateAppUser", this.item_to_edit)
        .then(() => {
          //Save modifications
          this.show_editing = false;
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("modifyed_member"),
            text: this.$t("modified_user_ok", {
              username: this.item_to_edit.username
            }),
            color: "accent"
          });

          // We update current_user if needed
          if (this.current_user.id === this.item_to_edit.id) {
            var user = this.getUserById(this.current_user.id);
            user.token = this.current_user.token;
            this.$store.dispatch("updateCredentials", user);
          }

          // Go to list
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_editing = false;
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    setNewAppUser: function() {
      this.saving = true;

      var vm = this;
      var item_to_edit = this.item_to_edit;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("creating_member"),
        color: "secondary"
      });

      // After validating all data, we check this username is not used
      this.$store
        .dispatch("createUser", this.item_to_edit)
        .then(() => {
          this.saving = false;
          this.show_new_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("created_member"),
            text: this.$t("modified_user_ok", {
              username: this.item_to_edit.username
            }),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.saving = false;
          this.show_new_member = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    checkPwd() {
      return this.pwd1 == this.pwd2 ? true : false;
    },
    validateEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },
  computed: {
    group_email_templates() {
      return this.email_templates.filter(et => et.group);
    },
    hotel_email_templates() {
      return this.email_templates.filter(et => et.hotel);
    },
    restaurant_email_templates() {
      return this.email_templates.filter(et => et.restaurant);
    },
    email_templates() {
      return [
        {
          value: "",
          text: "- " + this.$t("no_email_template") + " -",
          group: true,
          hotel: true,
          restaurant: true
        },
        {
          value: "group-report",
          text: this.$t("group-report"),
          group: true,
          hotel: false,
          restaurant: false
        },
        {
          value: "daily-report",
          text: this.$t("daily-report"),
          group: false,
          hotel: true,
          restaurant: false
        },
        // {
        //   value: "daily-report-ly",
        //   text: this.$t("daily-report-ly"),
        //   group: false,
        //   hotel: true,
        //   restaurant: false
        // },
        // {
        //   value: "daily-revenue-report",
        //   text: this.$t("daily-revenue-report"),
        //   group: false,
        //   hotel: true,
        //   restaurant: false
        // },
        // {
        //   value: "reputation-report",
        //   text: this.$t("reputation-report"),
        //   group: false,
        //   hotel: true,
        //   restaurant: true
        // },
        {
          value: "highlights-report",
          text: this.$t("highlights-report"),
          group: true,
          hotel: true,
          restaurant: false
        },
        {
          value: "hotelsdot-rms",
          text: this.$t("hotelsdot_rms_report"),
          group: false,
          hotel: true,
          restaurant: false,
          visibility: "rms_hotelsdot"
        },
        {
          value: "hotelsdot-group-rms",
          text: this.$t("hotelsdot_group_rms_report"),
          group: true,
          hotel: false,
          restaurant: false,
          visibility: "rms_hotelsdot"
        },
        {
          value: "axel-rms",
          text: this.$t("axel_rms_report"),
          group: false,
          hotel: true,
          restaurant: false,
          visibility: "rms_axel"
        },
        {
          value: "axel-rms-pdf",
          text: this.$t("axel_rms_pdf_report"),
          group: true,
          hotel: true,
          restaurant: false,
          visibility: "rms_axel"
        }
      ];
    },
    headers() {
      return [
        {
          text: this.$t("username"),
          align: "start",
          sortable: true,
          value: "username"
        },
        {
          text: this.$t("name"),
          align: "center",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("access_to"),
          align: "center",
          sortable: true,
          value: "access_to"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions"
        }
      ];
    },
    contentStyle() {
      // Mandatory
      var margin_top = this.image_height - 40;
      return "position:relative; margin-top:-" + margin_top + "px";
    },
    active_member() {
      return this.users.filter(user => user.active);
    },
    user_count() {
      return this.users.length;
    },
    active_member_count() {
      return this.active_member.length;
    },
    accesible_hotels() {
      // Just used while editing
      // Used in case saving changes to set user hotel visibilities
      var hotels = [];
      if (Object.keys(this.item_to_edit).length > 0) {
        Object.values(this.item_to_edit.hotel).forEach(function(element) {
          if (element.has_access) {
            hotels.push(element.id);
          }
        });
      }
      return hotels;
    },
    pwd_error() {
      // If no data, no errors
      if (this.pwd.length == 0) {
        return false;
      }
      return this.pwd.length < 8 ? true : false;
    },
    pwd1_error() {
      // If no data, no errors
      if (this.pwd1.length == 0) {
        return false;
      }
      return this.pwd1.length < 8 ? true : false;
    },
    pwd2_error() {
      // If no data, no errors
      if (this.pwd2.length == 0) {
        return false;
      }
      return this.pwd2.length < 8 || !this.checkPwd ? true : false;
    },
    can_submit() {
      return !this.pwd1_error &&
        !this.pwd2_error &&
        this.pwd1.length > 0 &&
        this.pwd2.length > 0
        ? true
        : false;
    },
    repeatPasswordRules() {
      return [
        v => !!v || this.$t("cant_be_empty"),
        v => (v && v.length >= 8) || this.$t("at_least_8_character"),
        v => v == this.new_password || this.$t("no_equal")
      ];
    },
    filteredUsers() {
      return this.users.filter(user => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.username.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    isSuperuser() {
      return this.item_to_edit && this.item_to_edit.profile_id === 99;
    },
    ...mapState({
      users: state => state.user.users,
      user: state => state.user.user,
      new_member_model: state => state.user.new_member_model,
      available_permissions: state => state.user.available_permissions,
      snackbar: state => state.snackbar
    }),
    ...mapGetters({
      name_rules: "name_rules",
      email_rules: "email_rules",
      date_rules: "date_rules",
      password_rules: "password_rules",
      current_user: "current_user",
      dialogHeight: "dialogHeight",
      getUserById: "getUserById"
    })
  }
};
</script>
<style>
.hotel_selected {
  background-color: #0084c8 !important;
}
</style>
