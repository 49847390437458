<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :color="isGroup ? '#ffffff' : '#f1f1f1'"
  >
    <template v-slot:header_options>
      <v-btn
        color="white"
        class="mt-2"
        rounded
        outlined
        small
        @click="addAutomation"
      >
        <v-icon small>mdi-plus</v-icon>
        Añadir automatización
      </v-btn>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <automations-app
      v-else
      ref="automations-app"
      :automations="automations"
    ></automations-app>
  </a-page>
</template>

<script>
import aPage from "@/components/aPage";
import automationsApp from "@/components/app/automationsApp";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms";

export default {
  name: "automations",
  components: {
    aPage,
    automationsApp,
    aHotelSelectorRms
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    automations: [
      {
        id: 1,
        name: "Automatización  1",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget odio feugiat, tincidunt risus in",
        trigger: {
          type: "kpi",
          period: ["2024-11-01", "2024-11-29"],
          week_days: [1, 3, 5],
          excluded_days: ["2024-11-05", "2024-11-22"],
          eval_period: "day",
          eval_kpi: {
            kpi: "room_revenue",
            comparison: ">",
            value: 100
          },
          eval_compare: "stly"
        },
        actions: [
          {
            name: "Aumentar el precio de las habitaciones un 5%",
            type: "price",
            value: 5,
            icon: "alert.svg"
          },
          {
            name: "Notificamos por email a cmonjo@aureo.es",
            type: "email",
            value: "cmonjo@aureo.es",
            icon: "send-email.svg"
          }
        ],
        historical: [
          {
            date: "2024-11-01 13:00:00",
            effort: "medio",
            name:
              "La ocupación ha bajado del 69% al 65% en el dia 17 de Octubre, lo que supone un 4% de bajada respecto al STLY"
          },
          {
            date: "2024-11-02 22:21:02",
            effort: "alto",
            name:
              "La ocupación ha bajado del 69% al 65% en el dia 17 de Octubre, lo que supone un 4% de bajada respecto al STLY"
          }
        ]
      },
      {
        id: 2,
        name: "Automatización  2",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget odio feugiat, tincidunt risus in",
        trigger: {
          type: "kpi",
          period: ["2024-12-05", "2024-12-22"],
          week_days: [1, 3, 5],
          excluded_days: ["2024-12-10", "2024-12-12"],
          eval_period: "day",
          eval_kpi: {
            kpi: "room_revenue",
            comparison: ">",
            value: 100
          },
          eval_compare: "stly"
        },
        actions: [
          {
            name: "Aumentar el precio de las habitaciones un 5%",
            type: "price",
            value: 5,
            icon: "alert.svg"
          }
        ],
        historical: [
          {
            date: "2020-01-01 13:00:00",
            effort: "medio",
            name:
              "La ocupación ha bajado del 69% al 65% en el dia 17 de Octubre, lo que supone un 4% de bajada respecto al STLY"
          },
          {
            date: "2020-01-02 22:21:02",
            effort: "alto",
            name:
              "La ocupación ha bajado del 69% al 65% en el dia 17 de Octubre, lo que supone un 4% de bajada respecto al STLY"
          }
        ]
      }
    ]
  }),
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 70 : 200;
    }
  },
  methods: {
    addAutomation() {
      this.automations.push({
        id: this.automations.length + 1,
        name: "Automatización " + (this.automations.length + 1),
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget odio feugiat, tincidunt risus in",
        trigger: {
          type: "kpi",
          period: ["2024-11-01", "2024-11-29"],
          week_days: [1, 3, 5],
          excluded_days: ["2024-11-05", "2024-11-22"],
          eval_period: "day",
          eval_kpi: {
            kpi: "room_revenue",
            comparison: ">",
            value: 100
          },
          eval_compare: "stly"
        },
        actions: [
          {
            name: "Aumentar el precio de las habitaciones un 5%",
            type: "price",
            value: 5,
            icon: "alert.svg"
          },
          {
            name: "Notificamos por email a cmonjo@aureo.es",
            type: "email",
            value: "cmonjo@aureo.es",
            icon: "send-email.svg"
          }
        ],
        historical: [
          {
            date: "2024-11-01 13:00:00",
            effort: "medio",
            name:
              "La ocupación ha bajado del 69% al 65% en el dia 17 de Octubre, lo que supone un 4% de bajada respecto al STLY"
          },
          {
            date: "2024-11-02 22:21:02",
            effort: "alto",
            name:
              "La ocupación ha bajado del 69% al 65% en el dia 17 de Octubre, lo que supone un 4% de bajada respecto al STLY"
          }
        ]
      });
    }
  }
};
</script>
