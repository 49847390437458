<template>
  <tr class="row-data">
    <td
      @click="$emit('clickOnDateCell', date)"
      class="day-data sticky-columns-shadow"
      :class="[color_class, { weekend: isWeekend }]"
      style="width: 200px"
    >
      <div class="cell" v-if="!isSummary">
        <div style="width: 100%; gap: 3px" class="d-flex flex-column">
          <div class="d-flex justify-start pl-5">
            <span v-html="day_of_week"></span>
            <v-chip v-if="is_today" dark x-small color="blue" class="ml-2">{{
              $t("today")
            }}</v-chip>
            <v-spacer></v-spacer>
            <a-rms-strategy-day-events
              v-for="impact in impacts"
              :key="impact.impact"
              :impact="impact"
              :events="events"
              :date="date"
            />
          </div>
          <div class="d-flex justify-start pl-5 grey--text">
            <span v-html="day_of_week_vs"></span>
            <v-spacer></v-spacer>
            <a-rms-strategy-day-events
              v-for="impact_vs in impacts_vs"
              :key="impact_vs.impact"
              :impact="impact_vs"
              :events="events_vs"
              :date="date_vs"
            />
          </div>
        </div>
      </div>
      <div class="cell justify-start" v-else>
        <div class="d-flex justify-start pl-5">
          <span>{{ $t("result_period") }}</span>
        </div>
      </div>
    </td>
    <td
      v-for="column in nonStickyColumnHeaders"
      :key="`${date}-${column.group}-${column.column}`"
      :class="[
        {
          'sticky-columns-shadow': column.column === lastStickyColumn.column
        },
        { weekend: isWeekend },
        getClass(
          configStrategyTable[column.group].columns[column.column].kpi,
          getValue(column),
          column
        ),
        { 'br-bolder': column.column === lastColumnOfEachGroup[column.group] },
        { previous: isPrevious },
        { summary: isSummary }
      ]"
      @mouseover="$emit('hoveredKpi', column)"
      @mouseleave="$emit('hoveredKpi', '')"
    >
      <div style="position:relative; height: 100%">
        <div class="cell">
          <template v-if="column.group.startsWith('pickup_')">
            <v-row class="mx-0 pt-1">
              <v-col
                :cols="
                  kpiMode === 'hide_vs' || rms.strategy_vs_spot !== 'stly'
                    ? 12
                    : 6
                "
                class="px-1 py-0"
              >
                <a-kpi
                  :kpi="
                    configStrategyTable[column.group].columns[column.column].kpi
                  "
                  :value="getStaticKpiValue(column)"
                  x_small
                  :class="{
                    'pt-4': rms.strategy_vs_spot !== 'stly'
                  }"
                  :absolute="kpiMode === 'absolute'"
                />
              </v-col>
            </v-row>
            <v-row
              class="pt-1 mx-0 align-center"
              v-if="rms.strategy_vs_spot === 'stly'"
            >
              <v-col :cols="kpiMode === 'hide_vs' ? 12 : 6" class="px-1 py-0">
                <a-kpi
                  :kpi="
                    configStrategyTable[column.group].columns[column.column].kpi
                  "
                  :value="
                    getStaticKpiValue(
                      column,
                      `pickup_stly_${column.group.slice(-1)}`
                    )
                  "
                  x_small
                  class="kpi-vs"
                  :absolute="kpiMode === 'absolute'"
                />
              </v-col>
              <v-col
                :cols="kpiMode === 'hide_vs' ? 12 : 6"
                class="pa-0"
                v-if="kpiMode !== 'hide_vs'"
              >
                <a-kpi
                  :kpi="
                    configStrategyTable[column.group].columns[column.column].kpi
                  "
                  :value="getStaticKpiValue(column)"
                  :vs_values="[
                    getStaticKpiValue(
                      column,
                      `pickup_stly_${column.group.slice(-1)}`
                    )
                  ]"
                  :vs_periods="[rms.strategy_vs_spot]"
                  x_small
                  hide_kpi
                  left
                  v-if="rms.strategy_vs_spot === 'stly'"
                  :absolute="kpiMode === 'absolute'"
                  shorten
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row class="mx-0 pt-1">
              <v-col :cols="kpiMode === 'hide_vs' ? 12 : 6" class="px-1 py-0">
                <a-kpi
                  :kpi="
                    configStrategyTable[column.group].columns[column.column].kpi
                  "
                  :value="getValue(column)"
                  x_small
                  :absolute="kpiMode === 'absolute'"
                />
              </v-col>
            </v-row>
            <v-row class="pt-1 mx-0 align-center">
              <v-col :cols="kpiMode === 'hide_vs' ? 12 : 6" class="px-1 py-0">
                <a-kpi
                  :kpi="
                    configStrategyTable[column.group].columns[column.column].kpi
                  "
                  :value="getValue(column, rms.strategy_vs_spot)"
                  x_small
                  class="kpi-vs"
                  :absolute="kpiMode === 'absolute'"
                />
              </v-col>
              <v-col
                :cols="kpiMode === 'hide_vs' ? 12 : 6"
                class="pa-0"
                v-if="kpiMode !== 'hide_vs'"
              >
                <a-kpi
                  :kpi="
                    configStrategyTable[column.group].columns[column.column].kpi
                  "
                  :value="getValue(column)"
                  :vs_values="[getValue(column, rms.strategy_vs_spot)]"
                  :vs_periods="[rms.strategy_vs_spot]"
                  x_small
                  hide_kpi
                  left
                  :absolute="kpiMode === 'absolute'"
                  shorten
                />
              </v-col>
            </v-row>
          </template>
        </div>
        <div
          v-if="
            showProgressPercent(
              configStrategyTable[column.group].columns[column.column].kpi
            )
          "
          class="percent-progress"
          :style="getPercentStyle(getValue(column))"
        ></div>
      </div>
    </td>
  </tr>
</template>
<script>
import aRmsStrategyDayEvents from "@/components/rms/aRmsStrategyDayEvents";
import aKpi from "@/components/rms_ui/aKpi.vue";

import { getFriendlyDayStrategy } from "@/utils.js";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";

export default {
  name: "a-rms-strategy-day-table-row",
  emits: ["clickOnDateCell", "hoveredKpi"],
  components: { aKpi, aRmsStrategyDayEvents },
  props: {
    row: Object,
    date: String,
    nonStickyColumnHeaders: Array,
    lastStickyColumn: Object,
    configStrategyTable: Object,
    lastColumnOfEachGroup: Object,
    isSummary: Boolean,
    kpiMode: String
  },
  methods: {
    showProgressPercent(kpi) {
      let type = this.get_kpi(kpi).data_format;
      if (type === "percent1" || type === "percent2") {
        return true;
      } else {
        return false;
      }
    },
    getPercentStyle(value) {
      return "width:" + value + "%;";
    },
    getKpiPercentClassByValue(value) {
      if (value < 20) {
        return "kpi-percent-range-0-20";
      } else if (value >= 20 && value < 40) {
        return "kpi-percent-range-20-40";
      } else if (value >= 40 && value < 60) {
        return "kpi-percent-range-40-60";
      } else if (value >= 60 && value < 80) {
        return "kpi-percent-range-60-80";
      } else if (value >= 80) {
        return "kpi-percent-range-80-100";
      }
    },
    getClass(kpi, value, column) {
      // If type of kpi is percent we must add a class to the cell named  kpi-percent-range-0-20, kpi-percent-range-20-40, kpi-percent-range-40-60, kpi-percent-range-60-80, kpi-percent-range-80-100
      let type = this.get_kpi(kpi).data_format;
      if (value === null) return "";
      if (column.column === "occupancy") {
        return this.getKpiPercentClassByValue(100 - value);
      } else if (
        type === "percent0" ||
        type === "percent1" ||
        type === "percent2"
      ) {
        return this.getKpiPercentClassByValue(value);
      } else if (column.group === "roomtype") {
        return !this.isSummary &&
          value > this.configStrategyTable.roomtype.columns[column.column].limit
          ? "limit"
          : "";
      } else if (column.group === "pickup_4") {
        if (
          this.configStrategyTable[column.group].columns[column.column].min &&
          this.configStrategyTable[column.group].columns[column.column].max
        ) {
          // rescale the value with min and max
          const { min, max } = this.configStrategyTable[column.group].columns[
            column.column
          ];
          let scaledValue = ((value - min) / (max - min)) * 100;
          return this.getKpiPercentClassByValue(100 - scaledValue);
        }
      } else if (column.group.startsWith("pickup_")) {
        return value <=
          this.configStrategyTable[column.group].columns[column.column].limit
          ? "limit"
          : "";
      } else if (
        column.group === "inventory" &&
        (column.column === "available" || column.column === "available_pace")
      ) {
        return !this.isSummary &&
          value <=
            this.configStrategyTable[column.group].columns[column.column].limit
          ? "limit"
          : "";
      }

      return "";
    },
    getCityMKDKpiValue(column, vs) {
      const value =
        column.column === "MKD-Final" || vs
          ? this.getStaticKpiValue(column, vs)
          : this.getStaticKpiValue(
              { column: "MKD-Final", group: "cityMKD" },
              vs
            ) - this.getStaticKpiValue(column, vs);
      // Customer wants to see availability, not demand
      // If showing the vs value, 0 means no value, but in current value, 0 means 100% availability
      return vs
        ? value > 0
          ? 100 - value
          : value
        : value >= 0
        ? 100 - value
        : value;
    }
  },
  computed: {
    isWeekend() {
      return !this.isSummary && Vue.moment(this.date).isoWeekday() > 4;
    },
    isPrevious() {
      return (
        !this.isSummary &&
        Vue.moment(this.date).isBefore(
          Vue.moment()
            .endOf("day")
            .add(-1, "days")
        )
      );
    },
    getStaticKpiValue() {
      return function(column, vs = undefined) {
        const spotIndex = vs
          ? vs
          : this.configStrategyTable[column.group] &&
            this.configStrategyTable[column.group].columns[column.column].block;
        return this.row &&
          this.row[spotIndex] &&
          this.configStrategyTable[column.group] &&
          (this.configStrategyTable[column.group].columns[column.column].zero ||
            this.row[spotIndex][
              this.configStrategyTable[column.group].columns[column.column].kpi
            ])
          ? this.row[spotIndex][
              this.configStrategyTable[column.group].columns[column.column].kpi
            ]
          : null;
      };
    },
    getDynamicKpiValue() {
      return function(column, dynamicKey = "roomtype", vs = undefined) {
        const spotIndex = vs
          ? vs
          : this.configStrategyTable[column.group].columns[column.column].block;

        return this.row &&
          this.row[spotIndex] &&
          this.row[spotIndex][dynamicKey] &&
          this.row[spotIndex][dynamicKey][column.column] &&
          this.row[spotIndex][dynamicKey][column.column][
            this.configStrategyTable[column.group].columns[column.column].kpi
          ]
          ? this.row[spotIndex][dynamicKey][column.column][
              this.configStrategyTable[column.group].columns[column.column].kpi
            ]
          : null;
      };
    },
    getValue() {
      return function(column, vs = undefined) {
        if (column.group === "roomtype") {
          return this.getDynamicKpiValue(column, "roomtype", vs);
        } else if (column.group === "segment") {
          return this.getDynamicKpiValue(column, "segment", vs);
        } else if (column.group === "cityMKD") {
          return this.getCityMKDKpiValue(column, vs);
        } else if (column.column === "room_revenue_other") {
          return (
            this.getStaticKpiValue(
              { column: "room_revenue_total", group: "revenue" },
              vs
            ) -
            this.getStaticKpiValue(
              { column: "room_revenue", group: "revenue" },
              vs
            )
          );
        } else {
          return this.getStaticKpiValue(column, vs);
        }
      };
    },
    color_class() {
      if (!this.row || !this.row.otb || !this.row.bud) return "neutral";

      let diff = this.row.otb.occupancy - this.row.bud.occupancy;
      if (diff > 0) return "positive";
      if (diff < 0) return "negative";
      return "neutral";
    },
    day_of_week() {
      return getFriendlyDayStrategy(this.date, true);
    },
    day_of_week_vs() {
      return getFriendlyDayStrategy(this.date_vs, true);
    },
    is_today() {
      return this.date === Vue.moment().format("YYYY-MM-DD");
    },
    events() {
      let events = this.date
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.date).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
      return events;
    },
    events_vs() {
      let events = this.date_vs
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.date_vs).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
      return events;
    },
    impacts() {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          color: i.color,
          count: this.events.filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    impacts_vs() {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          count: this.events_vs.filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    date_vs() {
      let date_vs = {};
      let items = this.calendar_store.hotel_comparative_dates;
      if (Object.keys(items).length > 0 && items.hasOwnProperty(this.date)) {
        date_vs = items[this.date].date_vs;
      }
      return date_vs;
    },
    ...mapState({
      rms: state => state.rms,
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      get_kpi: "get_kpi"
    })
  }
};
</script>
<style lang="scss" scoped>
.sticky-columns-shadow {
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.15),
    2px 0px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.08);
}
tr.summary {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.15),
    0px -2px 0px 0px rgba(0, 0, 0, 0.12), 0px -4px 0px 0px rgba(0, 0, 0, 0.08);
}

.cell {
  // border-top: $borderType $borderColorV;

  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  min-width: 100%;
  text-wrap: wrap;
  text-transform: uppercase;
  // display: flex;
  margin: 4px 0;
  justify-content: center;
  align-items: center;
  &.kpi-row {
    border-right: $borderType $borderColorV;
    border-bottom: 3px solid transparent;
  }
  &.kpi-hovered {
    border-bottom: 3px solid #2d6ece;
  }
}

table {
  border-spacing: 0;
}

th {
  // border-left: none;
  border: none;
  border-right: $borderType $borderColorV;
  border-bottom: $borderType $borderColorV;
  // padding: 5px;
  width: 100px;
  min-width: 100px;
  position: sticky;
  top: 0;
  background: white;
  color: #a0a0a0;
  font-weight: normal;
  height: $minHeightColRow;
  &:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    color: #e0e0e0;
    font-weight: normal;
    z-index: 2;
    // border-bottom: $borderType $borderColorV;
    // border-right: $borderType $borderColorV;
  }
}
.header-row {
  th {
    border-right: none;
    border-bottom: none;
    top: $minHeightColRow;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
      0px 1px 0px 0px rgba(0, 0, 0, 0.15), 2px 0px 0px 0px rgba(0, 0, 0, 0.11),
      0px 2px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.07),
      0px 4px 0px 0px rgba(0, 0, 0, 0.08);
    // &:nth-child(2) {
    //   position: sticky;
    //   left: 100px;
    //   box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
    //     0px 1px 0px 0px rgba(0, 0, 0, 0.15),
    //     2px 0px 0px 0px rgba(0, 0, 0, 0.11),
    //     0px 2px 0px 0px rgba(0, 0, 0, 0.12),
    //     4px 0px 0px 0px rgba(0, 0, 0, 0.07),
    //     0px 4px 0px 0px rgba(0, 0, 0, 0.08);
    // }
    &:nth-child(1) {
      z-index: 2;
      border-bottom: $borderType $borderColorV;
    }
  }
}

td {
  border: none;
  // border-left: none;
  // border-right: $borderType $borderColorV;
  // border-bottom: $borderType $borderColorV;
  // padding: 0 5px;
  width: 130px;
  min-width: 130px;
  background-color: white;
  height: $minHeightColRow + 4px;

  border-left: $borderType $borderColorV;
  border-right: 3px solid transparent;
  border-bottom: $borderType $borderColorV;
  padding: 5px 5px 2px 5px;

  &:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    color: #e0e0e0;
    font-weight: normal;
    z-index: 1;
    padding: 5px;
  }
  // &:nth-child(2) {
  //   position: sticky;
  //   left: 100px;
  //   width: 100px;
  //   min-width: 100px;
  // }

  &:hover {
    background-color: #f5fdff !important;
  }

  .percent-progress {
    content: "";
    position: absolute;
    bottom: 0px;
    // left: 3px;
    width: calc(100% - 6px);
    height: 4px;
    border-radius: 5px;
    margin-top: 5px;
  }
  &.kpi-percent-range-0-20 {
    background-color: rgba(181, 68, 3, 0.2) !important;
    &.summary {
      background-color: #f0d9cc !important;
    }
    .percent-progress {
      background-color: rgba(181, 68, 3, 1) !important;
    }
  }
  &.kpi-percent-range-20-40 {
    background-color: rgba(219, 131, 0, 0.2) !important;
    &.summary {
      background-color: #f7e6cc !important;
    }
    .percent-progress {
      background-color: rgba(219, 131, 0, 1) !important;
    }
  }
  &.kpi-percent-range-40-60 {
    background-color: rgba(246, 185, 0, 0.2) !important;
    &.summary {
      background-color: #fdf1cc !important;
    }
    .percent-progress {
      background-color: rgba(246, 185, 0, 1) !important;
    }
  }
  &.kpi-percent-range-60-80 {
    background-color: rgba(158, 143, 2, 0.2) !important;
    &.summary {
      background-color: #ebe8cc !important;
    }
    .percent-progress {
      background-color: rgba(158, 143, 2, 1) !important;
    }
  }
  &.kpi-percent-range-80-100 {
    background-color: rgba(79, 131, 2, 0.2) !important;
    &.summary {
      background-color: #dbe6cc !important;
    }
    .percent-progress {
      background-color: rgba(79, 131, 2, 1) !important;
    }
  }
}

tr.row-data {
  td.day-data {
    font-size: 0.8rem;
    font-weight: 700;
    color: #555;
    width: 200px;
    text-transform: uppercase;
    cursor: pointer;

    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 3px;
      height: calc(100% - 8px);
      width: 4px;
      background-color: grey;
      z-index: 10;
      border-radius: 5px;
      margin: 4px 0;
    }
    &.positive::after {
      background-color: rgb(23, 171, 112);
    }
    &.negative::after {
      background-color: rgb(172, 0, 0);
    }
    &.neutral::after {
      background-color: orange;
    }
  }
  &:hover {
    td.sticky-columns-shadow {
      border-right: 3px solid #2d6ece;
    }
  }

  td.weekend {
    background-color: #fffef4;
  }

  td.previous {
    opacity: 1;
    background-color: #e5e5e5;
    &:hover {
      opacity: 1;
    }
  }
  &.summary {
    td {
      background-color: #ebebeb;
    }
  }
  td.limit {
    background-color: rgb(240, 216, 209) !important;
  }
}

.br-bolder {
  border-right: 2px solid #ccc !important;
}
.kpi-vs {
  color: #6e6e6e;
}
</style>
