<template>
  <div :id="$options.name">
    <v-row>
      <v-col :cols="num_columns">
        <v-text-field
          v-model="search"
          background-color="white"
          prepend-inner-icon="mdi-magnify"
          class="search-field"
          placeholder="Buscar automatización"
          rounded
          clearable
          hide-details
        ></v-text-field>
        <div class="d-flex flex-column mt-4 automations-wrapper">
          <v-card
            v-for="automation in automations_filtered"
            :key="automation.id"
            class="automation elevation-0"
            :class="{
              editing:
                automation_to_edit && automation_to_edit.id === automation.id
            }"
            @click="editAutomation(automation.id)"
          >
            <v-card-text>
              <div
                class="d-flex"
                :class="
                  automation_to_edit !== null ? 'flex-column' : 'align-center'
                "
                :style="
                  automation_to_edit !== null ? 'gap: 15px;' : 'gap: 30px;'
                "
              >
                <div class="d-flex flex-column">
                  <div class="text-subtitle-1 font-weight-medium">
                    {{ automation.name }}
                  </div>
                  <div>{{ automation.description }}</div>
                </div>
                <div class="d-flex chips">
                  <v-chip outlined small>
                    <div class="d-flex">
                      <v-icon color="grey" size="16">
                        mdi-calendar-clock-outline
                      </v-icon>
                      <span>
                        {{ getDate(automation.trigger.period[0]) }} -
                        {{ getDate(automation.trigger.period[1]) }}
                      </span>
                    </div>
                  </v-chip>
                  <v-chip small color="grey lighten-3">
                    <span>
                      {{ $t(automation.trigger.eval_kpi.kpi) }}
                    </span>
                  </v-chip>
                  <v-chip
                    v-if="automation_to_edit === null"
                    small
                    color="grey lighten-3"
                  >
                    <span>
                      {{ automation.trigger.eval_kpi.comparison }}
                    </span>
                  </v-chip>
                  <v-chip
                    v-if="automation_to_edit === null"
                    small
                    color="grey lighten-3"
                  >
                    <span> {{ automation.trigger.eval_kpi.value }}% </span>
                  </v-chip>
                  <v-chip
                    v-if="automation_to_edit === null"
                    small
                    color="grey lighten-3"
                  >
                    <span>
                      {{ $t(automation.trigger.eval_compare) }}
                    </span>
                  </v-chip>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col
        v-if="automation_to_edit !== null"
        :cols="num_columns"
        class="mt-4"
      >
        <v-card>
          <v-card-title>
            <div>{{ automation_to_edit.name }}</div>
            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editing_automation = true">
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="showEditColumn('historical')">
                  <v-list-item-title>
                    Historial
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn icon @click="closeEditAutomation()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div>{{ automation_to_edit.description }}</div>
            <div class="d-flex flex-column mt-12 actions-wrapper">
              <v-card
                class="action-card"
                :class="{
                  editing: editing_trigger
                }"
                outlined
              >
                <v-card-title
                  class="text-body-1 font-weight-medium grey lighten-4 py-1 px-4"
                  style="line-height: 1.3;"
                >
                  <div>1. Desencadetante</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    small
                    fab
                    @click="showEditColumn('trigger')"
                  >
                    <v-icon>
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                  <div class="action-icon">
                    <v-img
                      src="/img/automations/lupa.svg"
                      max-width="25"
                      contain
                    />
                  </div>
                </v-card-title>
                <v-card-text class="py-8 white">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex flex-column action-name">
                      <div class="grey--text lighten-5 mb-2">
                        Cuando sucede el evento:
                      </div>
                      <div class="text-body-1 font-weight-medium">
                        La ocupación ha bajado un 3% durante el periodo
                        comprendido entre el 15 y el 20 de Octubre
                      </div>
                    </div>
                    <div>
                      <v-img
                        src="/img/automations/trigger.svg"
                        max-width="65"
                        width="100%"
                        contain
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                v-for="(action, index) in automation_to_edit.actions"
                :key="action.name"
                class="action-card"
                :class="{
                  editing: editing_action && action_to_edit.name === action.name
                }"
                outlined
              >
                <v-card-title
                  class="text-body-1 font-weight-medium grey lighten-4 py-1 px-4"
                  style="line-height: 1.3;"
                >
                  <div>{{ index + 2 }}. Acción</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    color="transparent"
                    small
                    fab
                    @click="editingAction(action)"
                  >
                    <v-icon>
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                  <v-btn elevation="0" color="transparent" small fab>
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <div class="action-icon">
                    <v-img
                      src="/img/automations/rocket.svg"
                      max-width="25"
                      contain
                    />
                  </div>
                </v-card-title>
                <v-card-text class="py-8">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex flex-column action-name">
                      <div class="text-body-1 font-weight-medium">
                        {{ action.name }}
                      </div>
                    </div>
                    <div>
                      <v-img
                        :src="'/img/automations/' + action.icon"
                        max-width="65"
                        width="100%"
                        contain
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="num_columns" class="mt-4">
        <v-card>
          <template v-if="editing_automation">
            <v-card-title>
              <div>Configuración automatización</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="editing_automation = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="automation_to_edit.name"
                label="Nombre"
                outlined
                rounded
                dense
              ></v-text-field>
              <v-textarea
                v-model="automation_to_edit.description"
                label="Descripción"
                outlined
                rounded
                dense
              ></v-textarea>
            </v-card-text>
          </template>
          <template v-else-if="editing_trigger">
            <v-card-title>
              <div>Configuración Desencadenante</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeEditColumn()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-select
                v-model="automation_to_edit.trigger.type"
                :items="trigger_types"
                label="Tipo"
                class="mb-4"
                outlined
                rounded
                dense
                hide-details
              ></v-select>
              <div class="text-button grey--text text--darken-1">
                ¿CUÁNDO QUIERES QUE SE EVALÚE?
              </div>
              <v-card class="mb-4" color="grey lighten-4" flat>
                <v-card-text class="py-6">
                  <v-text-field
                    v-model="automation_to_edit.trigger.period"
                    label="Periodo de evaluación"
                    prepend-inner-icon="mdi-calendar-clock-outline"
                    background-color="white"
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </v-card-text>
              </v-card>
              <div class="text-button grey--text text--darken-1">
                ¿QUÉ PERIODO QUIERES EVALUAR?
              </div>
              <v-card class="mb-4" color="grey lighten-4" flat>
                <v-card-text class="py-6">
                  <v-select
                    v-model="automation_to_edit.trigger.week_days"
                    :items="trigger_week_days"
                    label="Días de aplicación"
                    background-color="white"
                    multiple
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-select>
                  <div class="text-caption grey--text text--darken-2 mb-2">
                    Días de exclusión
                  </div>
                  <div class="d-flex flex-column" style="gap: 10px">
                    <div
                      v-for="day in automation_to_edit.trigger.week_days"
                      :key="day"
                      class="d-flex align-center"
                    >
                      <v-text-field
                        v-model="automation_to_edit.trigger.excluded_days"
                        prepend-inner-icon="mdi-calendar-clock-outline"
                        background-color="white"
                        outlined
                        rounded
                        dense
                        hide-details
                      ></v-text-field>
                      <v-btn icon>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <div class="text-button grey--text text--darken-1">
                ¿QUÉ DEBEMOS EVALUAR?
              </div>
              <v-card class="mb-4" color="grey lighten-4" flat>
                <v-card-text class="py-6">
                  <v-select
                    v-model="automation_to_edit.trigger.eval_period"
                    :items="trigger_periods"
                    label="Periodo"
                    background-color="white"
                    class="mb-4"
                    multiple
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-select>
                  <div class="text-caption grey--text text--darken-2 mb-2">
                    KPI
                  </div>
                  <v-row class="px-2 mb-8">
                    <v-col cols="4" class="py-0 px-1">
                      <v-select
                        v-model="automation_to_edit.trigger.eval_kpi.kpi"
                        :items="trigger_kpis"
                        background-color="white"
                        class="flex-grow-1 flex-shrink-1"
                        outlined
                        rounded
                        dense
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="4" class="py-0 px-1">
                      <v-select
                        v-model="automation_to_edit.trigger.eval_kpi.comparison"
                        :items="trigger_comparisons"
                        background-color="white"
                        class="flex-grow-1 flex-shrink-1"
                        outlined
                        rounded
                        dense
                        hide-details
                      ></v-select
                    ></v-col>
                    <v-col cols="4" class="py-0 px-1">
                      <v-text-field
                        v-model="automation_to_edit.trigger.eval_kpi.value"
                        background-color="white"
                        class="flex-grow-1 flex-shrink-1"
                        append-outer-icon="mdi-percent"
                        outlined
                        rounded
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-select
                    v-model="automation_to_edit.trigger.eval_compare"
                    :items="trigger_compares"
                    label="Respecta a"
                    background-color="white"
                    multiple
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-select>
                </v-card-text>
              </v-card>
            </v-card-text>
          </template>
          <template v-else-if="editing_action">
            <v-card-title>
              <div>Configuración Acción</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeEditColumn()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text> </v-card-text>
          </template>
          <template v-else-if="show_historical">
            <v-card-title>
              <div>Historial</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeEditColumn()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="d-flex flex-column" style="gap:10px">
                <v-card
                  v-for="historical in automation_to_edit.historical"
                  :key="historical.name"
                  color="grey lighten-4"
                  flat
                >
                  <v-card-text>
                    <div class="font-weight-medium">
                      {{ historical.name }}
                    </div>
                    <div class="d-flex justify-space-between align-center mt-4">
                      <v-chip color="secondary">
                        {{ historical.effort }}
                      </v-chip>
                      <div class="text-caption grey--text text--darken-2">
                        {{ getFriendlyDate(historical.date) }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import * as Utils from "@/utils.js";

export default {
  name: "automation-app",
  props: {
    automations: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    automation_to_edit: null,
    action_to_edit: null,
    editing_automation: false,
    editing_trigger: false,
    editing_action: false,
    show_historical: false,
    search: "",
    trigger_types: [
      { text: "KPI", value: "kpi" },
      { text: "Fecha", value: "date" }
    ],
    trigger_week_days: [
      { text: "Lunes", value: 1 },
      { text: "Martes", value: 2 },
      { text: "Miércoles", value: 3 },
      { text: "Jueves", value: 4 },
      { text: "Viernes", value: 5 },
      { text: "Sábado", value: 6 },
      { text: "Domingo", value: 7 }
    ],
    trigger_periods: [
      { text: "Día", value: "day" },
      { text: "Pickup -1", value: "pickup_1" }
    ],
    trigger_kpis: [
      { text: "Room revenue", value: "room_revenue" },
      { text: "RevPar", value: "revpar" },
      { text: "ADR", value: "adr" },
      { text: "Ocupación", value: "occupancy" }
    ],
    trigger_comparisons: [
      { text: "Mayor que", value: ">" },
      { text: "Menor que", value: "<" },
      { text: "Igual a", value: "=" }
    ],
    trigger_compares: [
      { text: "STLY", value: "stly" },
      { text: "Budget", value: "budget" }
    ]
  }),
  computed: {
    automations_filtered() {
      return this.automations;
    },
    num_columns() {
      let columns = 12;
      if (this.automation_to_edit !== null) {
        columns = 6;
      }
      if (
        this.editing_automation ||
        this.editing_trigger ||
        this.editing_action ||
        this.show_historical
      ) {
        columns = 4;
      }
      return columns;
    }
  },
  methods: {
    getDate(date) {
      return Vue.moment(date).format("DD/MM/YYYY");
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDatetime(date, true);
    },
    editAutomation(id) {
      this.closeEditAutomation();
      this.automation_to_edit = this.automations.find(
        automation => automation.id === id
      );
    },
    editingAction(action) {
      this.action_to_edit = action;
      this.showEditColumn("actions");
    },
    showEditColumn(column) {
      this.editing_automation = false;
      this.editing_trigger = false;
      this.editing_action = false;
      this.show_historical = false;
      if (column === "historical") {
        this.show_historical = true;
      } else if (column === "actions") {
        this.editing_action = true;
      } else if (column === "trigger") {
        this.editing_trigger = true;
      } else {
        this.editing_automation = true;
      }
    },
    closeEditAutomation() {
      this.automation_to_edit = null;
      this.action_to_edit = null;
      this.editing_automation = false;
      this.editing_trigger = false;
      this.editing_action = false;
      this.show_historical = false;
    },
    closeEditColumn() {
      this.editing_trigger = false;
      this.editing_action = false;
      this.show_historical = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#automation-app {
  --automations-blue: #4880c2;
  .btn-edit-automation {
    cursor: pointer;
  }
  .automations-wrapper {
    gap: 10px;
    .automation {
      border-right: 4px solid transparent;
      transition: all 0.3s;
      &.editing {
        border-right: 4px solid var(--automations-blue);
        .v-card__title {
          color: var(--automations-blue);
        }
      }
      &:focus:before {
        opacity: 0;
      }
      .chips {
        gap: 10px;
        .v-chip {
          border-color: rgba(0, 0, 0, 0.5);
          .d-flex {
            gap: 5px;
          }
        }
      }
    }
  }
  .actions-wrapper {
    gap: 80px;
    .action-card {
      position: relative;
      &:not(:first-child)::before {
        background-color: #e8e8e8;
        content: "";
        height: 81px;
        position: absolute;
        top: -81px;
        left: 50%;
        opacity: 1;
        transform: translateX(-50%);
        width: 2px;
      }
      /* &::after {
        content: "+";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      } */
      &.editing {
        border-color: var(--automations-blue);
        .v-card__title {
          background-color: var(--automations-blue) !important;
          color: white !important;
          .v-icon {
            color: white !important;
          }
        }
      }
      .action-name {
        max-width: 45%;
      }
      .action-icon {
        background-color: white;
        border-radius: 100%;
        border: 1px solid var(--automations-blue);
        padding: 8px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .search-field .v-input__prepend-inner {
    margin-top: 20px;
  }
}
</style>
